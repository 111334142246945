@import 'assets/styles/main';

.container {
  height: 100vh;
  overflow: hidden;
  .back-btn {
    position: absolute;
    width: 10vw;
    left: 10vw;
    top: 7vh;
    padding: 0 0 50px 0;
    color: white;
    font-size: 1.3em;
    .back-img {
      width: 50px;
    }
  }
  .home-btn {
    position: absolute;
    width: 12vw;
    right: 12vw;
    top: 7vh;
    padding: 0 0 50px 0;
  }
  .petal-img {
    position: relative;
    margin: 15vh 0 0 5vw;
    text-align: center;

    .petal {
      transform: rotate(-20deg);
      width: 90vw;
      height: 65vh;
    }
    .editor {
      border: 2px solid white;
      box-shadow: 0px 2px 2px 1px rgb(199, 199, 199) inset;
      top: 26vh;
      left: 47%;
      height: 30vh;
      width: 70vw;
      transform: translate(-50%, -50%);
      position: absolute;
      padding: 2% 2% 2% 0;
      margin: 5% 0 0 0;
      background-color: rgba(255, 255, 255, 0.514);
      border-radius: 15px;

      .scrollBar {
        width: 100%;
        height: 85%;
        padding: 0 0 0 2%;
        overflow-y: scroll;
        overflow-x: scroll;
      }
      /* 아래의 모든 코드는 영역::코드로 사용 */

      .scrollBar::-webkit-scrollbar {
        margin: 0 5px;
        width: 10px; /* 스크롤바의 너비 */
      }

      .scrollBar::-webkit-scrollbar-thumb {
        padding: 0 5px 0 0;
        height: 30%; /* 스크롤바의 길이 */
        background: #b9b9b9; /* 스크롤바의 색상 */
        border-radius: 10px;
      }
      .header {
        display: flex;
        padding: 5px 3% 0 3%;
        justify-content: space-between;
        .date {
          color: gray;
        }
        .nation {
          color: rgb(0, 0, 0);
        }
        .nationimg {
          height: 16px;
          width: 16px;
        }
      }
      .textarea {
        padding: 5px;
        text-align: start;
        white-space: pre-line;
      }
      // TODO : center 안먹히는 이유 찾기
      .default {
        .upload-image {
          display: none;
        }

        .preview-img {
          margin: 0 1px 0 0;
          height: 30vh;
          width: 50vw;
          box-shadow: 0px 2px 2px 1px rgb(199, 199, 199) inset;
          background-color: rgba(255, 255, 255, 0.514);
          border: 2px solid rgb(255, 255, 255);
          border-radius: 20px;
          width: 100%;
          height: 100%;
          border-radius: 20px;
          overflow: hidden;
        }
      }
      img {
        object-fit: cover;
        width: 100%;
        height: 100%;
      }
    }
    .byte-limit {
      color: gray;
      font-size: 0.8em;
      padding: 10px 0 0px 100px;
    }
  }
  .minsize-msg {
    color: gray;
    font-size: 0.8em;
    text-align: center;
  }
  .btn {
    font-size: 1.7em;
    text-align: center;
    color: #ffffff;
  }
}

.guides {
  scroll-snap-type: y mandatory;
  overflow-x: hidden;
  overflow-y: scroll;
  scroll-behavior: smooth;
  height: calc(var(--vh, 1vh) * 100);
  
  .guide {
    width: 100%;
    height: calc(var(--vh, 1vh) * 100);
    scroll-snap-align: center;
    border-bottom: 2px solid white;
    position: relative;

    img {
      width: 100%;
      height: 100%;
    }
  }
}

.start {
  position: absolute;
  bottom: 10%;
  left: 30%;
  width: 40%;
  z-index: 3;
  text-align: center;
}

.guide-button {
  font-size: 2rem;
  font-weight: bold;
  color: white;
  animation: blink 1.5s ease-in-out infinite alternate;
  -webkit-animation: blink 1.5s ease-in-out infinite alternate; 
  -moz-animation: blink 1.5s ease-in-out infinite alternate;

  &:hover {
    opacity: 1;
  }

  @keyframes blink { 
    0% { 
      opacity: 0.3;
    }
    100% { 
      opacity: 1;
    }
  }
}
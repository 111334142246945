@import 'assets/styles/main';

.container {
  position: relative;
  width: 100%;
  height: 100vh;
  // minHeight: '100vh',
  min-height: -webkit-fill-available;
  overflow: hidden;
  .title {
    color: white;
    font-size: 40px;
    text-align: center;
    padding: 5px 0 0 0;
    text-shadow: 1px 1px 2px rgb(255, 255, 255), 0 0 1em rgb(99, 99, 99),
      0 0 0.2em rgb(71, 71, 71);
    animation: ani 1s infinite alternate;
  }

  .cloud2 {
    position: absolute;
    left: 20%;
    top: 15%;
    z-index: 100;
    animation: fadeInLeft1 50s;
    animation-iteration-count: infinite;
    .cloud2-img {
      width: 500px;
    }
  }
  .cloud1 {
    position: absolute;
    right: 0%;
    bottom: 35%;
    z-index: 100;
    animation: fadeInLeft2 20s;
    animation-iteration-count: infinite;
    .cloud1-img {
      width: 500px;
    }
  }
  .cloud3 {
    position: absolute;
    top: 25%;
    left: 0%;
    z-index: 100;
    animation: fadeInLeft3 30s;
    animation-iteration-count: infinite;
    .cloud3-img {
      width: 500px;
    }
  }
  .cloud4 {
    position: absolute;
    top: 25%;
    left: 0%;
    z-index: 100;
    animation: fadeInLeft3 30s;
    animation-iteration-count: infinite;
    .cloud4-img {
      width: 500px;
    }
  }
  .cloud5 {
    position: absolute;
    top: 45%;
    left: -30%;
    z-index: 100;
    animation: fadeInLeft5 30s;
    animation-iteration-count: infinite;
    .cloud5-img {
      width: 500px;
    }
  }
}

@keyframes fadeInLeft1 {
  0% {
    opacity: 1;
    transform: translate3d(-100%, 0, 0);
  }
  to {
    opacity: 1;
    transform: translate3d(400%, 0, 0);
  }
}

@keyframes fadeInLeft2 {
  0% {
    opacity: 1;
    transform: translate3d(100%, 0, 0);
  }
  to {
    opacity: 1;
    transform: translate3d(-400%, 0, 0);
  }
}

@keyframes fadeInLeft3 {
  0% {
    opacity: 1;
    transform: translate3d(-100%, 0, 0);
  }
  to {
    opacity: 1;
    transform: translate3d(400%, 0, 0);
  }
}

@keyframes fadeInLeft5 {
  0% {
    opacity: 1;
    transform: translate3d(-100%, 0, 0);
  }
  to {
    opacity: 1;
    transform: translate3d(600%, 0, 0);
  }
}

@keyframes ani {
  0% {
    transform: translate(0, 9px);
  }
  100% {
    transform: translate(0, 11px);
  }
}

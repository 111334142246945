@import 'assets/styles/main';

.home-btn {
  position: absolute;
  width: 12vw;
  right: 12vw;
  top: 10vh;
  padding: 0 0 50px 0;
}

.back-btn {
  position: absolute;
  width: 10vw;
  left: 10vw;
  top: 7vh;
  padding: 0 0 50px 0;
  color: white;
  font-size: 1.3em;
}

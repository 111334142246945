@font-face {
  font-family: 'BASE FONT';
  src: url('../../fonts/GowunDodum-Regular.ttf');
}

// @font-face {
//   font-family: 'BASE FONT';
//   src: url('../../fonts/Gong Gothic Medium.ttf');
// }

@font-face {
  font-family: 'BASE FONT';
  src: url('../../fonts/Besley-Italic-VariableFont_wght.ttf');
  unicode-range: U+0020-007E;
}

@font-face {
  font-family: 'SUB FONT';
  src: url('../../fonts/Gong Gothic Medium.ttf');
}

.container {
  // height: 100vh;
  height: calc(var(--vh, 1vh) * 100);
  background-image: url('../../assets/images/garden_back.webp');
  z-index: 5000;
  background-size: cover;
  background-position: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  .inner-container {
    width: 100%;
    height: 100vh;
    display: flex;

    .settings-container {
      height: 40%;
      width: 75%;
      margin-top: 100px;
      margin-left: auto;
      margin-right: auto;
      background-color: whitesmoke;
      border-radius: 5px;
      opacity: 0.8;
      align-items: center;

      .title {
        font-size: 2rem;
        text-align: center;
        margin-top: 10px;
        margin-bottom: 30px;
      }

      .sub-title {
        display: inline;
        font-size: 1.5rem;
        margin-left: 10px;
      }

      .content-box {
        margin-bottom: 30px;
        display: flex;
        justify-content: center;
        align-items: center;
      }

      .content {
        display: inline-block;
        margin-left: 30px;
      }
    }
  }
  .btns {
    margin-left: auto;
    margin-top: 30px;
    margin-right: 30px;
  }
  .btn {
    height: 40px;
    width: 40px;
    margin-bottom: 15px;
  }

  .delete-btn-box {
    flex-direction: column;
    text-align: right;
    margin: auto;
    align-items: center;
  }
  .delete-btn {
    background-color: beige;
    padding: 5px 10px;
    border-radius: 5px;
  }
}

.skip {
  padding: 1vh 3vw;
  width: auto;
  z-index: 500;
  position: absolute;
  top: 80px;
  right: 20px;
  font-weight: 600;
  color: white;
  border: 2px solid white;
}

.container {
  // height: 100vh;
  height: calc(var(--vh, 1vh) * 100);
  z-index: 5000;
  background-image: url('../../assets/images/garden_back.webp');
  background-size: cover;
  padding-right: 20px;
  padding-top: 20px;
  background-position: center;

  // .inner-container {
  // }
  .btns {
    z-index: 5000;
    display: inline;
    text-align: right;
  }
  .btn {
    z-index: 5000;
    height: 40px;
    width: 40px;
    margin-bottom: 15px;
  }

  // .btns {
  //   margin-left: auto;
  //   margin-top: 30px;
  //   margin-right: 30px;
  // }
  // .btn {
  //   height: 30px;
  //   width: 30px;
  //   margin-bottom: 15px;
  // }

  .cover {
    height: 60vh;
    position: relative;
    // background-image: linear-gradient(90deg, rgb(218, 218, 218) 0%, rgba(247,247,247,0) 18%);
    // perspective: 1200px;
    box-shadow: 0 0 50px rgba(0, 0, 0, 0.1);
    width: calc(min(95vw, 400px));
    // margin-left: 50px;
    // border: 6px solid saddlebrown;
    border-radius: 2%;
    left: -2%;
    z-index: 5000;
    background-color: saddlebrown;
    background-image: linear-gradient(
      0deg,
      rgba(0, 0, 0, 0.5) 0%,
      rgba(0, 0, 0, 0.05) 60%
    );
    // background-image: linear-gradient(0deg, rgba(247,247,246, 0.05) 0%, rgba(247,247,247,0.2) 25%);
  }

  .book {
    width: 80%;
    height: 97%;
    display: flex;
    perspective: 1400px;
    // background-color: #F5F5F5;
    box-shadow: 0 0 30px rgba(0, 0, 0, 0.3);
    position: relative;
    left: 18%;
    top: 1%;

    &__page {
      position: absolute;
      width: 100%;
      height: 100%;
      transform: rotateY(0deg);
      transition: transform 1.4s cubic-bezier(0.645, 0.045, 0.355, 1);
      transform-origin: 0% 0%;
      background-color: #e1dfd4;
      // background-image: linear-gradient(90deg, rgb(218, 218, 218) 0%, rgba(247,247,247,0) 18%);
      background-image: linear-gradient(
        90deg,
        rgba(39, 39, 39, 0.15) 0%,
        rgba(247, 247, 247, 0) 18%
      );
      // backface-visibility: hidden;

      &__content {
        display: grid;
        grid-template-columns: 1fr 1fr;
        grid-template-rows: repeat(2, minmax(50%, auto));
        // grid-template-rows: 1fr 1fr;
        width: 86%;
        height: 90%;
        margin-left: 10%;
        text-align: center;
        max-height: 90%;
        object-fit: cover;
        grid-auto-rows: 1fr 1fr;
        align-self: center;

        &__flower {
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: center;
          height: 100%;
          font-size: 1.2rem;
          // max-height: 1%;

          img {
            max-width: 80%;
            object-fit: cover;
          }
        }
      }

      &__first {
        // transition: transform 1.4s cubic-bezier(0.645, 0.045, 0.355, 1);
        transform: rotateY(-180deg);
        z-index: 500;
      }
    }

    input[type='radio'] {
      display: none;

      &:checked + .book__page {
        transition: transform 1.2s cubic-bezier(0.645, 0.045, 0.355, 1);
        transform: rotateY(-180deg);
      }
    }
  }
}
